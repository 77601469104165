import DatasetIcon from '@mui/icons-material/Dataset';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Container, FormControl, IconButton, InputBase, MenuItem, Paper, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import PaginationComponent from '../../common/component/PaginationComponent';
import { useAppDispatch } from '../../common/redux/reduxHooks';
import { RootState } from '../../common/redux/store';
import imageback from '../../images/img-fond-fonce.svg';
import { OrderStatus } from "../model/Order";
import { getFilteredPaginedOrders } from '../redux/ordersActions';
import OrdersByState from "./OrdersByState";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { frFR } from '@mui/x-date-pickers/locales';
import { Dayjs } from 'dayjs';
import "dayjs/locale/fr";
import { useLocation } from 'react-router-dom';

interface OrdersPageProps {

}

const OrdersPage: FunctionComponent<OrdersPageProps> = () => {

  const dayjs = require('dayjs');
  dayjs.locale('fr');

  const location = useLocation();

  const [isListDisplay, setisListDisplay] = useState<boolean>(location.state && location.state['isListDisplay'] ? location.state['isListDisplay'] : false);

  const [isDisplayCurrentOrder, setisDisplayCurrentOrder] = useState<boolean>(location.state && !location.state['isDisplayCurrentOrder'] ? location.state['isDisplayCurrentOrder'] : true);

  const dispatch = useAppDispatch();

  const ordersSelector = useSelector((rootState: RootState) => rootState.orders);

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(window.innerWidth > 1280 ? 8 : 4);

  const [currentPage, setcurrentPage] = useState<number>(location.state && location.state['previousPage'] ? location.state['previousPage'] : 1);

  const [searchValue, setsearchValue] = useState<string>(location.state && location.state['searchValue'] ? location.state['searchValue'] : '');

  const [status, setstatus] = useState<number | null>(location.state && location.state['status'] ? location.state['status'] : null);

  const [filter, setfilter] = useState<'number' | 'orderDate' | 'deliveryDate' | 'company' | 'status' | 'applicant' | 'empty'>(location.state && location.state['filter'] ? location.state['filter'] : 'empty');

  const [startDate, setstartDate] = useState<Dayjs | null>(location.state && location.state['startDate'] ? dayjs(location.state['startDate']) : null);

  const [endDate, setendDate] = useState<Dayjs | null>(location.state && location.state['endDate'] ? dayjs(location.state['endDate']) : null);

  useEffect(() => {
    if (rowsPerPage) {
      dispatch(getFilteredPaginedOrders({
        nbResultPerPage: rowsPerPage,
        type: getTypeForWS(),
        numPage: currentPage,
        property: filter,
        value: getValueForWS(),
        start_date: startDate ? startDate.format('YYYY-MM-DD') : null,
        end_date: endDate ? endDate.format('YYYY-MM-DD') : null,
      }));
    }
  }, [rowsPerPage]);

  const handleSwitch = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue === 'list') {
      setisListDisplay(true);
    }
    else {
      setisListDisplay(false);
    }
  };


  const handleSwitchState = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue === OrderStatus.CURRENT) {
      setisDisplayCurrentOrder(true);
      //1 en cours 
      dispatch(getFilteredPaginedOrders({
        nbResultPerPage: rowsPerPage,
        type: 1,
        numPage: 1,
        property: filter,
        value: getValueForWS(),
        start_date: startDate ? startDate.format('YYYY-MM-DD') : null,
        end_date: endDate ? endDate.format('YYYY-MM-DD') : null,
      }))
    }
    else {
      setisDisplayCurrentOrder(false);
      //6 passées
      dispatch(getFilteredPaginedOrders({
        nbResultPerPage: rowsPerPage,
        type: 6,
        numPage: 1,
        property: filter,
        value: getValueForWS(),
        start_date: startDate ? startDate.format('YYYY-MM-DD') : null,
        end_date: endDate ? endDate.format('YYYY-MM-DD') : null,
      }))
    }
    setcurrentPage(1);
  };

  function onChangePage(event: React.ChangeEvent<unknown>, page: number) {
    setcurrentPage(page);
    if (filter === 'number' || filter === 'applicant' || filter === 'company') {
      if (searchValue !== '') {
        dispatch(getFilteredPaginedOrders({
          nbResultPerPage: rowsPerPage,
          type: getTypeForWS(),
          numPage: page,
          property: filter,
          value: getValueForWS(),
          start_date: null,
          end_date: null,
        }))
      }
    }
    else if (filter === 'orderDate' || filter === 'deliveryDate') {
      if (startDate && endDate) {
        dispatch(getFilteredPaginedOrders({
          nbResultPerPage: rowsPerPage,
          type: getTypeForWS(),
          numPage: page,
          property: filter,
          value: getValueForWS(),
          start_date: startDate.format('YYYY-MM-DD'),
          end_date: endDate.format('YYYY-MM-DD'),
        }))
      }
    }
    else if (filter === 'status') {
      if (status) {
        dispatch(getFilteredPaginedOrders({
          nbResultPerPage: rowsPerPage,
          type: getTypeForWS(),
          numPage: page,
          property: filter,
          value: getValueForWS(),
          start_date: null,
          end_date: null,
        }))
      }
    }
    else if (filter === 'empty') {
      dispatch(getFilteredPaginedOrders({
        nbResultPerPage: rowsPerPage,
        type: getTypeForWS(),
        numPage: page,
        property: filter,
        value: null,
        start_date: null,
        end_date: null,
      }))
    }
  }

  const handleChangeFilter = (event: SelectChangeEvent) => {
    setfilter(event.target.value as 'number' | 'orderDate' | 'deliveryDate' | 'company' | 'status' | 'applicant');
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setstatus(Number(event.target.value));
  };

  function getValueForWS(): string | number | null | undefined {
    if (filter === 'number' || filter === 'company' || filter === 'applicant') {
      return searchValue;
    }
    else if (filter === 'deliveryDate' || filter === 'orderDate') {
      return null;
    }
    else if (filter === 'status') {
      return status;
    }
    return null;
  }

  function resetFilter() {
    setisDisplayCurrentOrder(true)
    setfilter('empty');
    setstatus(null);
    setsearchValue('');
    setstartDate(null);
    setendDate(null);
    dispatch(getFilteredPaginedOrders({
      nbResultPerPage: rowsPerPage,
      type: 1,
      numPage: 1,
      property: filter,
      value: null,
      start_date: null,
      end_date: null,
    }));
    setcurrentPage(1);
  }

  function getTypeForWS(): 1 | 6 {
    // 1 = encours, 6 = passées
    return isDisplayCurrentOrder ? 1 : 6;
  }

  /**
   * check si les filtres sont renseignés pour pouvoir lancer une recherche
   */
  function startSearch(): boolean {
    if (filter === 'status') {
      if (status !== undefined) return true
    }
    else if (filter === 'number' || filter === 'company' || filter === 'applicant') {
      if (searchValue !== "") return true
    }
    else if (filter === 'deliveryDate' || filter === 'orderDate') {
      if (startDate !== null && endDate !== null) return true
    }
    return false;
  }

  function onClickSearch() {
    if (startSearch()) {
      setcurrentPage(1)
      dispatch(getFilteredPaginedOrders({
        nbResultPerPage: rowsPerPage,
        type: getTypeForWS(),
        numPage: 1,
        property: filter,
        value: getValueForWS(),
        start_date: (filter === 'orderDate' || filter === 'deliveryDate') && startDate ? startDate.format('YYYY-MM-DD') : null,
        end_date: (filter === 'orderDate' || filter === 'deliveryDate') && endDate ? endDate.format('YYYY-MM-DD') : null,
      }))
    }
  }

  return (
    <Box className="page-container"
      sx={{
        backgroundImage: `url(${imageback})`
      }}
    >
      <Container className='container' style={{ maxWidth: '170vh' }}>
        <Box display={'flex'} justifyContent={'space-between'} paddingY={4}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography className='title-page' variant='h4' component={'h1'} sx={{
              marginX: 7
            }}>
              Mes commandes
            </Typography>

            <ToggleButtonGroup
              value={isListDisplay}
              exclusive
              onChange={handleSwitch}
              aria-label="set way to view data"
            >
              <ToggleButton value="list" aria-label="list" className={isListDisplay ? 'white-button-selected' : 'white-button'}
                sx={{
                  backgroundColor: isListDisplay ? 'white' : '#30353f'
                }}
              >
                <FormatListBulletedIcon fontSize="large"
                  sx={{
                    color: isListDisplay ? 'black' : 'white'
                  }}
                />
              </ToggleButton>
              <ToggleButton value="card" aria-label="card" className={!isListDisplay ? 'white-button-selected' : 'white-button'}
                sx={{
                  backgroundColor: isListDisplay ? '#30353f' : 'white'
                }}
              >
                <DatasetIcon fontSize="large" className='icon-in-button'
                  sx={{
                    color: isListDisplay ? 'white' : 'black'
                  }}
                />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 520 }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
              <Select
                labelId="select-filter-label"
                id="select-filter"
                value={filter}
                onChange={handleChangeFilter}
                label="Filtre"
              >
                <MenuItem value={'empty'}><em>Saisir filtre</em></MenuItem>
                <MenuItem value={'number'}>N° commande</MenuItem>
                <MenuItem value={'orderDate'}>Date commande</MenuItem>
                <MenuItem value={'deliveryDate'}>Délai</MenuItem>
                <MenuItem value={'company'}>Société</MenuItem>
                <MenuItem value={'status'}>Statut</MenuItem>
                <MenuItem value={'applicant'}>Suivi par</MenuItem>
              </Select>
            </FormControl>
            {
              (filter === 'number' || filter === 'company' || filter === 'applicant' || filter === 'empty') &&
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Rechercher"
                inputProps={{ 'aria-label': 'rechercher' }}
                value={searchValue}
                onChange={(e) => setsearchValue(e.target.value)}
              />
            }
            {
              (filter === 'status') &&
              <FormControl variant="standard" sx={{ ml: 1, flex: 1 }}>
                <Select
                  labelId="select-status-label"
                  id="select-status"
                  value={status?.toString()}
                  onChange={handleChangeStatus}
                  label="Statuts"
                >
                  <MenuItem value={'1'}>{OrderStatus.CURRENT}</MenuItem>
                  <MenuItem value={'6'}>{OrderStatus.PAST}</MenuItem>
                </Select>
              </FormControl>
            }
            {
              (filter === 'orderDate' || filter === 'deliveryDate') &&
              <LocalizationProvider dateAdapter={AdapterDayjs} localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DatePicker 
                  className="date-input"
                  format="DD/MM/YYYY"
                  value={startDate}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue) {
                      setstartDate(newValue)
                    }
                  }}
                />
                <DatePicker
                  className="date-input"
                  format="DD/MM/YYYY"
                  value={endDate}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue) {
                      setendDate(newValue)
                    }
                  }}
                />
              </LocalizationProvider>
            }
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => {
              onClickSearch()
            }}>
              <SearchIcon />
            </IconButton>
            <IconButton type="button" sx={{ p: '10px' }} aria-label="remove" onClick={resetFilter}>
              <CloseIcon />
            </IconButton>
          </Paper>
        </Box>


        <ToggleButtonGroup
          value={isDisplayCurrentOrder}
          exclusive
          onChange={handleSwitchState}
          aria-label="set way to view data"
          sx={{
            marginX: 7,
            marginBottom: 3
          }}
        >
          <ToggleButton value={OrderStatus.CURRENT} aria-label="list" className={isDisplayCurrentOrder ? 'white-button-selected' : 'white-button'}
            sx={{
              backgroundColor: isDisplayCurrentOrder ? 'white' : '#30353f',
              color: isDisplayCurrentOrder ? '#30353f' : 'white',
              width: 160,
              fontSize: '1em',
              fontWeight: 'bold'
            }}
          >
            {OrderStatus.CURRENT}
          </ToggleButton>
          <ToggleButton value={OrderStatus.PAST} aria-label="card" className={!isDisplayCurrentOrder ? 'white-button-selected' : 'white-button'}
            sx={{
              backgroundColor: isDisplayCurrentOrder ? '#30353f' : 'white',
              color: isDisplayCurrentOrder ? 'white' : '#30353f',
              width: 160
            }}
          >
            {OrderStatus.PAST}
          </ToggleButton>
        </ToggleButtonGroup>

        {
          isDisplayCurrentOrder ?
            <Box marginX={7}>
              <OrdersByState 
                isInListDisplayMode={isListDisplay} 
                state={OrderStatus.CURRENT} 
                orders={ordersSelector.data.responsePaginedOrders.orders} 
                currentPage={currentPage}
                isDisplayCurrentOrder={isDisplayCurrentOrder}
                searchValue={searchValue}
                status={status}
                filter={filter}
                startDate={startDate}
                endDate={endDate}
              />
            </Box>
            :
            <Box marginX={7}>
              <OrdersByState 
                isInListDisplayMode={isListDisplay} 
                state={OrderStatus.PAST} 
                orders={ordersSelector.data.responsePaginedOrders.orders} 
                currentPage={currentPage} 
                isDisplayCurrentOrder={isDisplayCurrentOrder}
                searchValue={searchValue}
                status={status}
                filter={filter}
                startDate={startDate}
                endDate={endDate}
              />
            </Box>
        }

        <Box>
          <PaginationComponent
            currentPage={currentPage}
            nbTotalPage={ordersSelector.data.responsePaginedOrders.max_page}
            onChangePage={onChangePage}
          />
        </Box>
      </Container>
    </Box >
  );
}

export default OrdersPage;