import { Box, Button, Chip, Container, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import SuccessNewRequestComponent from "./SuccessNewRequestComponent";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import imageback from '../../images/img-fond-fonce.svg';
import './NewRequestPage.scss';
import DragDropFile from "../../common/component/DragDropFile";
import { useAppDispatch } from "../../common/redux/reduxHooks";
import { Estimate } from "../model/Estimate";
import { createEstimate } from "../redux/estimatesActions";
import { useSelector } from "react-redux";
import { RootState } from "../../common/redux/store";
import { estimatesSetResponseMessage } from "../redux/estimatesSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteApp } from "../../common/model/RouteApp";

interface NewRequestPageProps {

}

const NewRequestPage: FunctionComponent<NewRequestPageProps> = () => {

  const [isNewRequestSuccess, setisNewRequestSuccess] = useState<boolean>(false);

  const estimatesSelector = useSelector((state: RootState) => state.estimates);

  useEffect(() => {
    if (estimatesSelector.data.responseMessage?.status === 200) {
      setisNewRequestSuccess(true);
      dispatch(estimatesSetResponseMessage(undefined));
    }
  }, [estimatesSelector]);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [product, setproduct] = useState<string>('');
  const [errorProduct, seterrorProduct] = useState<string | undefined>(undefined);
  const [additionalInformation, setadditionalInformation] = useState<string>('');
  // const [quantity, setquantity] = useState<number>(0);
  // const [errorQuantity, seterrorQuantity] = useState<string | undefined>(undefined);
  const [listFiles, setlistFiles] = useState<File[]>([]);

  function onClickValidate() {
    if (!checkInputs()) {
      const estimateToEdit: Estimate = {
        product: product,
        quantity: 0,
        additional_information: additionalInformation,
        files: listFiles,
      }
      dispatch(createEstimate(estimateToEdit));
    }
  }

  function checkInputs(): boolean {
    // seterrorQuantity(undefined);
    seterrorProduct(undefined);
    let isError = false;
    // if (quantity === 0) {
    //   seterrorQuantity("Le champ est requis");
    //   isError = true;
    // }
    if (product.length === 0) {
      seterrorProduct("Le champ est requis");
      isError = true;
    }
    return isError;
  }

  function addFileList(fileList: FileList) {
    const lf = [...listFiles];
    for (let index = 0; index < fileList.length; index++) {
      lf.push(fileList[index]);
    }
    setlistFiles(lf);
  }

  function onClickDelete(fileName: string) {
    setlistFiles(listFiles.filter((f: File) => f.name !== fileName));
  }

  return (
    <>
      {
        !isNewRequestSuccess ?
          <Box
            className="page-container"
            sx={{
              backgroundImage: `url(${imageback})`
            }}
          >
            <Container className="container">
              <Box className="header">
                <Typography className='title-page' variant='h4' component="h1">
                  Demande de devis
                </Typography>
                <Button onClick={() => navigate(RouteApp.ESTIMATES, { state: {
                  currentPage: location.state['currentPage'],
                  isEstimateView: location.state['isEstimateView'],
                  searchValue: location.state['searchValue'],
                  status: location.state['status'],
                  filter: location.state['filter'],
                  startDate: location.state['startDate'],
                  endDate: location.state['endDate'],
                  titlePage: location.state['titlePage']
                }})} variant="contained" className="back-button" startIcon={<KeyboardBackspaceIcon />}>
                Retour
                </Button>
              </Box>
              <Box className="new-request-page-form-container">

                <Box className="new-request-page-top-container">
                  <TextField id="product" label="Référence demande" variant="outlined"
                    required
                    sx={{
                      width: '70%',
                      input: { color: 'black' },
                      label: { color: 'black' },
                      '& label.MuiFormLabel-root.Mui-focused': {
                        color: 'white',
                        top: { lg: '-18px', xl: '-28px' },
                        left: '-15px',
                        fontSize: { lg: '24px', xl: '34px' },
                      },
                      '& label.MuiInputLabel-shrink': {
                        color: 'white',
                        top: { lg: '-18px', xl: '-28px' },
                        left: '-15px',
                        fontSize: { lg: '24px', xl: '34px' },
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        }
                      },
                      backgroundColor: 'white',
                      borderRadius: '10px'
                    }}
                    value={product}
                    onChange={(e) => setproduct(e.target.value)}
                    inputProps={{ maxLength: 250 }}
                    error={errorProduct !== undefined}
                    helperText={errorProduct ? errorProduct : ''}
                  />

                  {/* <TextField className='new-request-page-quantity' id="quantity" label="Quantité" type="number"
                    required
                    sx={{
                      width: '70%',
                      input: { color: 'black' },
                      label: { color: 'black' },
                      '& label.MuiFormLabel-root.Mui-focused': {
                        color: 'white',
                        top: { lg: '-18px', xl: '-28px' },
                        left: '-15px',
                        fontSize: { lg: '24px', xl: '34px' },
                      },
                      '& label.MuiInputLabel-shrink': {
                        color: 'white',
                        top: { lg: '-18px', xl: '-28px' },
                        left: '-15px',
                        fontSize: { lg: '24px', xl: '34px' },
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        }
                      },
                      backgroundColor: 'white',
                      borderRadius: '10px'
                    }}
                    value={quantity}
                    onChange={(e) => setquantity(Number.parseInt(e.target.value))}
                    inputProps={{ min: 0 }}
                    error={errorQuantity !== undefined}
                    helperText={errorQuantity ? errorQuantity : ''}
                  /> */}
                </Box>

                <TextField
                  id="complementary-information"
                  className="new-request-page-complementary-information"
                  label="Informations : Merci de bien vouloir renseigner vos informations de contact ainsi que l'objet de votre demande."
                  multiline
                  sx={{
                    width: '77%',
                    input: { color: 'black' },
                    label: { color: 'black' },
                    '& label.MuiFormLabel-root.Mui-focused': {
                      color: 'white',
                      top: { lg: '-18px', xl: '-28px' },
                      left: '-15px',
                      fontSize: { lg: '21.5px', xl: '22.5px' },
                    },
                    '& label.MuiInputLabel-shrink': {
                      color: 'white',
                      top: { lg: '-18px', xl: '-28px' },
                      left: '-15px',
                      fontSize: { lg: '21.5px', xl: '22.5px' },
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: 'white',
                      },
                    },
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    marginBottom: { lg: undefined, xl: '5px' }
                  }}
                  maxRows={4}
                  value={additionalInformation}
                  onChange={(e) => setadditionalInformation(e.target.value)}
                />


                {
                  listFiles.length > 0 &&
                  <Box sx={{
                    backgroundColor: 'white',
                    width: 'calc(70% - 20px)',
                    padding: '10px',
                    borderRadius: '10px',
                    marginY: { lg: '5px', xl: '10px' }
                  }}>
                    <Stack direction="row" spacing={1}>
                      {
                        listFiles.map((f: File, indice: number) => {
                          return <Chip label={f.name} variant="outlined" onDelete={() => onClickDelete(f.name)} key={indice} />
                        })

                      }
                    </Stack>
                  </Box>
                }

                <Box className="new-request-page-bottom-container">
                  <DragDropFile message="Déposez vos photos ici ou " accept="image/*" addFileList={addFileList} />
                  <Button variant="contained" className="new-request-page-send-button" onClick={() => onClickValidate()}>
                    Valider
                  </Button>
                </Box>
              </Box>
            </Container>
          </Box>
          :
          <SuccessNewRequestComponent />
      }
    </>
  );
}

export default NewRequestPage;