import FastRewindIcon from '@mui/icons-material/FastRewind';
import { Box, Button, Grid, LinearProgress, Paper, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, Typography } from "@mui/material";
import CircularProgress from '@mui/joy/CircularProgress';
import React, { FunctionComponent, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RouteApp } from '../../common/model/RouteApp';
import imageback from '../../images/img-fond-fonce.svg';
import OrderProductsDetailsComponent from "./OrderProductsDetailsComponent";
import { useAppDispatch } from '../../common/redux/reduxHooks';
import { getOrderById } from '../redux/ordersActions';
import { useSelector } from 'react-redux';
import { RootState } from '../../common/redux/store';
import { OrderDetail } from '../model/OrderDetail';

const isResoFullHD: boolean = window.innerWidth > 1280;

interface OrderDetailPageProps {

}

const OrderDetailPage: FunctionComponent<OrderDetailPageProps> = () => {

  const location = useLocation();

  const { idOrder } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const orderSelector: OrderDetail | undefined = useSelector((rootState: RootState) => rootState.orders.data.orderDetail);
  const isLoading: boolean = useSelector((rootState: RootState) => rootState.orders.isLoader);

  const steps = [
    'Préparation',
    'Lancé',
    'Terminé',
  ];

  function getActiveStep(): number {
    if (!orderSelector || orderSelector?.status === null) {
      return -1;
    }

    return orderSelector.status;
  }

  const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#16C27D',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#16C27D',
      },
    },
  }));

  useEffect(() => {
    if (idOrder && orderSelector?.id !== Number.parseInt(idOrder)) {
      dispatch(getOrderById(Number.parseInt(idOrder)));
    }
  }, [idOrder]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        backgroundImage: `url(${imageback})`,
      }}
    >
      {
        isLoading ?
          <Box sx={{
            margin: 'auto',
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
          }}>
            <LinearProgress sx={{ width: '100%' }} />
            <Typography variant="h4" component="h2" sx={{ color: 'white', margin: 'auto' }}>Chargement...</Typography>
          </Box>
          :
          <Box
            sx={{
              margin: 'auto',
              width: '90%'
            }}
          >
            <Typography variant="h3" component='h1'
              sx={{
                color: 'white',
                fontSize: isResoFullHD ? '40px' : '25px',
                marginBottom: isResoFullHD ? 3 : 0,
                marginTop: isResoFullHD ? 3 : 8
              }}
            >
              N° Commande {' ' + orderSelector?.reference}
            </Typography>
            <Paper
              sx={{
                padding: isResoFullHD ? 4 : 3,
                borderBottomLeftRadius: 25,
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                maxHeight: isResoFullHD ? undefined : '35rem'
              }}
              square
            >
              <Grid container>
                <Grid item xs={4}>
                  <Button variant="outlined" color="error" startIcon={<FastRewindIcon fontSize="large" sx={{ width: '36px', height: '27px' }} />}
                    sx={{
                      borderWidth: 4,
                      borderColor: 'red',
                      width: isResoFullHD ? '292px' : '200px',
                      height: isResoFullHD ? '48px' : '35px',
                      '&:hover': {
                        borderWidth: 4,
                        borderColor: 'red',
                      }
                    }}
                    onClick={() => navigate(
                      RouteApp.ORDERS, { 
                        state: { 
                          previousPage: location.state['previousPage'],
                          isListDisplay: location.state['isListDisplay'], 
                          isDisplayCurrentOrder: location.state['isDisplayCurrentOrder'],
                          searchValue: location.state['searchValue'],
                          status: location.state['status'],
                          filter: location.state['filter'],
                          startDate: location.state['startDate'],
                          endDate: location.state['endDate']
                        }
                      }
                    )}
                  >
                    Commandes
                  </Button>
                </Grid>
                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Stepper activeStep={getActiveStep()} alternativeLabel connector={<CustomStepConnector />} sx={{ width: '60%' }}>
                    {steps.map((label) => (
                      <Step key={label} sx={{
                        '& .Mui-completed': {
                          color: '#16C27D',
                        },
                        '& .Mui-active': {
                          color: '#1976d2',
                        },
                      }}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <CircularProgress
                    color={"success"}
                    determinate
                    size= "md"
                    value={orderSelector?.completion}
                    sx={{
                      '--CircularProgress-size': isResoFullHD ? '100px' : '70px',
                      '--CircularProgress-trackThickness': isResoFullHD ? '13px' : '8px',
                      '--CircularProgress-progressThickness': isResoFullHD ? '13px' : '8px'
                    }}
                  >
                    {orderSelector?.completion}%
                  </CircularProgress>
                </Grid>
              </Grid>

              <Typography variant="subtitle1" component={'h3'} sx={{ fontWeight: 'bold', marginTop: isResoFullHD ? 4 : 0, marginBottom: isResoFullHD ? 4 : 2, fontSize: isResoFullHD ? '22px' : '18px' }}>
                INFORMATIONS
              </Typography>

              <Box sx={{ marginBottom: isResoFullHD ? 3 : 2 }}>
                <Typography variant="subtitle2" component={'span'} sx={{ fontWeight: 'bold', fontSize: isResoFullHD ? '20px' : '16px' }}>
                  N° de suivi:
                </Typography>
                <Typography variant="subtitle2" component={'span'} sx={{ fontWeight: 'bold', color: 'red', fontSize: isResoFullHD ? '20px' : '16px', marginLeft: '1em' }}>
                  {orderSelector?.internal_number}
                </Typography>
              </Box>

              <Grid container spacing={1} width={'80%'}>
                <Grid item xs={2.5}>
                  <Typography fontWeight={'bold'} fontSize={isResoFullHD ? '21px' : '17px'}>
                    Date de commande
                  </Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography fontWeight={'bold'} fontSize={isResoFullHD ? '21px' : '17px'}>
                    Date de livraison
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography fontWeight={'bold'} fontSize={isResoFullHD ? '21px' : '17px'}>
                    Société
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight={'bold'} fontSize={isResoFullHD ? '21px' : '17px'}>
                    Suivi par
                  </Typography>
                </Grid>

                <Grid item xs={2.5}>
                  <Typography fontSize={isResoFullHD ? '21px' : '17px'}>
                    {orderSelector?.order_date}
                  </Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography fontSize={isResoFullHD ? '21px' : '17px'}>
                    {orderSelector?.delivery_date}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography fontSize={isResoFullHD ? '21px' : '17px'}>
                    {orderSelector?.company?.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography fontSize={isResoFullHD ? '21px' : '17px'}>
                    {orderSelector?.contact?.concat_name}
                  </Typography>
                </Grid>
              </Grid>

              <Typography variant="subtitle1" component={'h3'} sx={{ fontWeight: 'bold', marginY: isResoFullHD ? 5 : 1, fontSize: isResoFullHD ? '22px' : '18px' }}>
                PRODUITS
              </Typography>

              <Grid container>
                <Grid item xs={8}>
                  <OrderProductsDetailsComponent orderLine={orderSelector?.order_line ? orderSelector?.order_line : []} />
                </Grid>
                <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                  <Button variant="outlined"
                    sx={{
                      marginY: 'auto',
                      width: isResoFullHD ? '292px' : '200px',
                      height: isResoFullHD ? '48px' : '35px',
                      color: 'black',
                      borderWidth: 4,
                      borderColor: 'black',
                      '&:hover': {
                        borderWidth: 4,
                        borderColor: 'black',
                      }
                    }}
                    onClick={() => navigate(
                      RouteApp.ORDERS + RouteApp.ORDER_DETAIL + "/" + orderSelector?.id + RouteApp.DATASHEETS, { 
                        state: { 
                          previousPage: location.state['previousPage'],
                          isListDisplay: location.state['isListDisplay'], 
                          isDisplayCurrentOrder: location.state['isDisplayCurrentOrder'],
                          searchValue: location.state['searchValue'],
                          status: location.state['status'],
                          filter: location.state['filter'],
                          startDate: location.state['startDate'],
                          endDate: location.state['endDate']
                        }
                      }
                    )}
                  >
                    Fiches techniques
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
      }


    </Box>
  );
}

export default OrderDetailPage;