import React, { FunctionComponent } from "react";
import './DragDropFile.scss';
import { ReactComponent as UploadIcon } from '../../images/Icon-file-upload.svg';

interface DragDropFileProps {
  message: string;
  addFileList: (fileList: FileList) => void;
  accept?: string;
  disable?: boolean;
}

const DragDropFile: FunctionComponent<DragDropFileProps> = (props: DragDropFileProps) => {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef<HTMLInputElement>(null);

  // handle drag events
  const handleDrag = function (e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    if (inputRef.current)
      inputRef.current.click();
  };

  function handleFiles(files: FileList) {
    props.addFileList(files);
  }

  return (
    <form style={{ width: '77%' }} id="form-file-upload" className={props.disable ? "drag-drop-file-container" : undefined} onDragEnter={(e) => handleDrag(e)} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={(e) => handleChange(e)} accept={props.accept} />
      <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
        <UploadIcon />
        <div id="container-message-file-upload">
          <p id="message-file-upload">{props.message}</p>
          <button className="upload-button" onClick={onButtonClick}>Parcourir</button>
        </div>
      </label>
      {dragActive && <div id="drag-file-element" onDragEnter={(e) => handleDrag(e)} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={(e) => handleDrop(e)}></div>}
    </form>
  );
}

export default DragDropFile;